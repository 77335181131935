import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookShareCount,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  PinterestIcon,
  PinterestShareCount,
  PinterestShareButton,
  RedditShareButton,
  RedditIcon,
  RedditShareCount,
  EmailShareButton,
  EmailIcon
} from 'react-share';

const Network = styled.div`
  vertical-align: top;
  display: inline-block;
  margin-right: 15px;
  text-align: center;
  .sharecount {
    margin-top: 3px;
    font-size: 12px;
  }
  .sharebutton {
    cursor: pointer;
    &:hover:not(:active) {
      opacity: 0.75;
    }
  }
`;
/** A simple social media sharing component. Could be improved by making events fire into google analytics
 *
 */
class Share extends Component {
  render() {
    const { url, title, image, cta } = this.props;
    return (
      <>
        <div>
          <h3>{cta}</h3>
        </div>
        <div>
          <Network>
            <FacebookShareButton
              url={url}
              quote={title}
              className="sharebutton"
            >
              <FacebookIcon size={32} />
              <FacebookShareCount url={url} className="sharecount">
                {count => count}
              </FacebookShareCount>
            </FacebookShareButton>
          </Network>
          <Network>
            <TwitterShareButton url={url} title={title} className="sharebutton">
              <TwitterIcon size={32} />
            </TwitterShareButton>
          </Network>
          <Network>
            <WhatsappShareButton
              url={url}
              title={title}
              separator=":: "
              className="sharebutton"
            >
              <WhatsappIcon size={32} />
            </WhatsappShareButton>
          </Network>
          <Network>
            <PinterestShareButton
              url={url}
              media={image}
              windowWidth={1000}
              windowHeight={730}
              className="sharebutton"
            >
              <PinterestIcon size={32} />
            </PinterestShareButton>

            <PinterestShareCount url={url} className="sharecount" />
          </Network>
          <Network>
            <RedditShareButton
              url={url}
              title={title}
              windowWidth={660}
              windowHeight={460}
              className="sharebutton"
            >
              <RedditIcon size={32} />
            </RedditShareButton>

            <RedditShareCount url={url} className="sharecount" />
          </Network>
          <Network>
            <EmailShareButton
              url={url}
              subject={title}
              body="Hey You might want to check out this blog"
              className="sharebutton"
            >
              <EmailIcon size={32} />
            </EmailShareButton>
          </Network>
        </div>
      </>
    );
  }
}

Share.propTypes = {
  /** Url for page sharing */
  url: PropTypes.string.isRequired,
  /** Page title required for sharing */
  title: PropTypes.string.isRequired,
  /** image Url for page sharing */
  image: PropTypes.string
};

export { Share };
