import styled from 'styled-components';

/** A simple cros close button. eg of use could be to close a modal or pannel */
const CloseButton = styled.button`
  background: black;
  color: white;
  font-size: 3rem;
  border: 0;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
`;

export { CloseButton };
