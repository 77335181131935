import React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Draw, CloseButton, MenuBookingFormWrapper } from '../layout/index.js';
import { Button } from '../common/index.js';

const Group = styled.div`
  margin-top: 2rem;
`;
/** Shows booking options in a hidden draw menu when not on a hostel page */
const MenuBooking = props => {
  return (
    <Draw open={props.open}>
      <header>
        <CloseButton title="close" onClick={props.toggleDraw}>
          &times;
        </CloseButton>
        <h3>Let's make a booking!</h3>
      </header>
      <MenuBookingFormWrapper />
      <Group>
        <h3>Got a booking for more than 10 of you?</h3>
        <p>
          We are the experts a large group bookings. Our dedicated groups
          co-ordiator will help make your life easy and may even have a few
          perks for you.
        </p>
        <Link to="/groups/">
          <Button primary>Check out group options</Button>
        </Link>
      </Group>
    </Draw>
  );
};

MenuBooking.propTypes = {
  /** determins if the menu is open or closed */
  open: PropTypes.bool,
  /** Trigger funtion on open and shutt the draw */
  toggleDraw: PropTypes.func
};

export { MenuBooking };
