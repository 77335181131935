export default function(amount, nodollar, includeDecimals) {
  const options = {
    currency: 'USD',
    minimumFractionDigits: 2
  };

  if (!nodollar) options.style = 'currency';
  // if its a whole, dollar amount, leave off the .00
  if (amount % 100 === 0 && !includeDecimals) options.minimumFractionDigits = 0;
  const formatter = new Intl.NumberFormat('en-US', options);
  return formatter.format(amount / 100);
}
