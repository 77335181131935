import styled from 'styled-components';
import media from '../layout/Media';

const FlexGalleryWrapper = styled.div.attrs(props => ({
  mobileLWidth: props.mobileLWidth || '209',
  laptopWidth: props.laptopWidth || '200',
  laptopLWidth: props.laptopLWidth || '210'
}))`
  display: flex;
  flex-wrap: wrap;

  @supports (display: grid) {
    display: grid;
    grid-gap: 1px;
    margin-top: 1rem;
    grid-template-columns: 1fr 1fr;

    @media ${media.mobileL} {
      grid-template-columns: repeat(
        auto-fit,
        minmax(${props => props.mobileLWidth}px, 1fr)
      );
    }

    @media ${media.laptop} {
      grid-template-columns: repeat(
        auto-fit,
        minmax(${props => props.laptopWidth}px, 1fr)
      );
    }

    @media ${media.laptopL} {
      grid-template-columns: repeat(
        auto-fit,
        minmax(${props => props.laptopLWidth}px, 1fr)
      );
    }

    .f-8 {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 3;
      background: ${props => props.theme.secondaryColor};
      @media only screen and (max-width: 500px) {
        grid-column: auto / span 2;
        grid-row: auto / span 2;
      }
    }
  }
`;

export { FlexGalleryWrapper };
