import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { HostelSearchForm } from '../layout/index.js';

/** A simple compnent to provide a list of hostel names to the menu booking form by using a staticquery */
const MenuBookingFormWrapper = ({ data }) => (
  <StaticQuery
    query={graphql`
      query menudraw {
        allDatoCmsHostel(filter: { locale: { eq: "en" } }) {
          edges {
            node {
              id
              slug
              title
            }
          }
        }
      }
    `}
    render={data => <HostelSearchForm hostels={data.allDatoCmsHostel} />}
  />
);

MenuBookingFormWrapper.propTypes = {
  data: PropTypes.object
};

export { MenuBookingFormWrapper };
