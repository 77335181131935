import styled from 'styled-components';

const Tag = styled.ul`
  margin: -5px;
  li {
    display: inline-block;
    padding: 8px 10px;
    background: #fff;
    border-radius: 3px;
    margin: 5px;
    color: ${props => props.theme.secondaryColor};
    font-size: 0.8rem;
  }
`;

export { Tag };
