import styled from 'styled-components';
import media from '../layout/Media';
/**
 * An Animated off screen draw which can be used for menus, booking forms, checkouts. Uses pure css for anmation
 */

const Draw = styled.div`
  padding: 2rem;
  position: relative;
  background: ${props => (props.light ? '#fff' : props.theme.black)};

  color: ${props => (props.light ? props.theme.black : '#fff')};
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  width: vw100
  min-width: 320px;
  bottom: 0;
  transform: translateX(100%);
  transition: all 0.3s;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
  z-index: 10;
  overflow: scroll;

  @media ${media.tablet} {
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    width: 40%
  }
  ${props => props.open && `transform: translateX(0);`};
  header {
    padding-bottom: 1rem;
    margin-top: 1rem;

    h3 {
      margin-top: 1rem;
    }
  }
  footer {
    border-top: 10px double ${props => props.theme.black};
    margin-top: 2rem;
    padding-top: 2rem;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    font-size: 3rem;
    font-weight: 900;
    p {
      margin: 0;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: scroll;
  }
`;

export { Draw };
