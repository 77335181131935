import styled from 'styled-components';
import media from '../layout/Media';

/**
 * Styling component for individual items for grid based mosaic style gallery with clickable tiles
 */
const FlexGalleryItem = styled.div.attrs(props => ({
  basis: props.basis || '250px',
  basisWide: props.basisWide || '400px'
}))`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 40%;
  background: #0b6070;
  margin: 1px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  min-height: 140px;

  @media ${media.tablet} {
    flex-basis: ${props => props.basis};
    min-height: 180px;
  }

  @media ${media.laptopL} {
    flex-basis: ${props => props.basisWide};
    min-height: 300px;
  }

  padding: 0 0 1rem;
  transition: all 0.6s ease-in-out;
  .gatsby-image-wrapper {
    transition: all 0.6s ease-in-out;
    filter: brightness(0.7);
    &:hover {
      transform: scale(1.2);
      filter: brightness(1);
    }
  }

  @supports (display: grid) {
    margin: 0;
    min-height: 160px;
  }
  h3 {
    margin: 0px;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    margin-top: 15%;
    left: 0;
    font-size: 1.3rem;
    letter-spacing: 0.04rem;
    padding: 1rem;
    text-align: center;
    line-height: 1.3rem;
    color: ${props => props.theme.white};
    width: 100%;
    pointer-events: none;

    @media ${media.laptopL} {
      padding-top: 8%;
    }
  }
`;
export { FlexGalleryItem };
