import React, { Component } from 'react';
import styled from 'styled-components';
import { Gallery } from './Gallery';

const ProductGalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

/** A component for rendering a product gallery. If there is only one image it will take up a full column width otherwise it will spit into columns */
class ProductGallery extends Component {
  render() {
    const productImg = this.props.productImage.map(photo =>
      Object.assign({
        srcSet: photo.fluid.srcSet,
        src: photo.fluid.src,
        caption: photo.title,
        fluid: photo.fluid,
        alt: photo.alt
      })
    );

    const imageNumber = this.props.productImage.length;

    let gallery;

    switch (imageNumber) {
      case 0:
        gallery = <></>;
        break;
      case 1:
        gallery = (
          <ProductGalleryWrapper>
            <Gallery images={productImg} minHeight="332px" />
          </ProductGalleryWrapper>
        );
        break;
      default:
        gallery = (
          <ProductGalleryWrapper>
            <Gallery images={productImg} minHeight="332px" />
          </ProductGalleryWrapper>
        );
        null;
    }
    const hasProductImage = this.props.hasProductImage;
    // return hasProductImage && gallery;
    return hasProductImage && gallery;
  }
}

export { ProductGallery };
