import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Section, Container } from '../common/';
import Tick from '../images/tick.svg';

const SellingPoints = styled.div`
  ul {
    list-style: none;
    margin: 0;
  }
  li {
    background-image: url(${Tick});
    background-repeat: no-repeat;
    padding-left: 2rem;
    font-size: 1rem;
    margin-bottom: 1.4rem;
    line-height: 1.6;
  }
  h3 {
    margin-bottom: 1rem;
  }
`;

const IntroContainer = styled(Container)`
  position: relative;
`;

const Intro = styled.div`
  margin-bottom: 1rem;
`;

const Heart = styled.img`
  position: absolute;
  bottom: -20px;
  right: 35px;
  float: right;
  text-align: right;
`;
/**
 * Blue backgrounded intro text use on hostel and group pages */
const IntroText = props => {
  return (
    <Section lightBlueBackground>
      <IntroContainer
        maxwidth="1000px"
        col="2"
        width="100px"
        mobcol="1fr"
        gap="3rem"
      >
        <div>
          <Intro dangerouslySetInnerHTML={{ __html: props.text }} />

          <SellingPoints>
            <h3>{props.reasons}</h3>
            <div dangerouslySetInnerHTML={{ __html: props.sellingPoints }} />
          </SellingPoints>
        </div>
      </IntroContainer>
    </Section>
  );
};

IntroText.propTypes = {
  text: PropTypes.string
};

export { IntroText };
