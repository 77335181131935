import React from 'react';
import styled from 'styled-components';
import formatMoney from '../../lib/formatMoney';

const PriceTag = styled.div`
  background: red;
  display: inline-block;
  position: absolute;
  top: 15px;
  right: 0px;
  padding: 1rem;

  p {
    margin: 0;
    color: #fff;
    text-align: right;
    font-weight: 700;
  }
`;
const FromTag = props => {
  return (
    <PriceTag>
      <p>Stay from {formatMoney(props.priceFrom)}</p>
    </PriceTag>
  );
};

export { FromTag };
