import styled from 'styled-components';

/**
 * Styled compnent for individual gallery elements
 */
const GalleryItem = styled.div`
  
  /* box-shadow: ${props => props.theme.shadow}; */
  
  overflow: hidden;
  position: relative;
  z-index: 2;
  padding: ${props => (props.inline ? 'none' : '33%')};
  width: ${props => (props.inline ? '250px' : 'none')};
  height: ${props => (props.inline ? '150px' : 'none')};
  margin-right: ${props => (props.inline ? '10px' : 'none')};
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  &:hover {
    transform: scale(1.05);
  }
`;

export { GalleryItem };
