import styled from 'styled-components';

const ScrollContainer = styled.div.attrs(props => ({
  maxwidth: props.maxwidth || 'none',
  padding: props.padding || '0 1.5rem 2.5rem 1.5rem',
  colwidth: props.colwidth || '13rem'
}))`
  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(20, ${props => props.colwidth});
    grid-gap: 15px;
  }
  display: flex;
  overflow-x: auto;
  scroll-snap-coordinate: 0 0;
  scroll-snap-points-x: repeat(100%);
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  will-change: transform;
  padding: ${props => props.padding};
  max-width: ${props => props.maxwidth};
  margin: 0 auto;

  > a {
    float: left;
  }
`;

export { ScrollContainer };
