import React from 'react';

/**
 * A compnent for pushing events into the GTM datalayer. Uses component did mount to ensure a window dom element is available.
 * Further work is needed on this component to make it more powerful
 */
export default class DataLayer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <></>;
  }
  componentDidMount() {
    const { event, pageType, destination } = this.props;

    {
      window.dataLayer.push({
        event: event,
        pageType: pageType,
        destination: destination
      });
    }
    {
      window.addEventListener('beforeinstallprompt', e => {
        e.userChoice.then(choiceResult => {
          ga('send', 'event', 'Add PWA to home', choiceResult.outcome);
        });
      });
    }
  }
}
