import styled from 'styled-components';
import media from '../../layout/Media';

const Section = styled.section.attrs(props => ({
  padding: props.padding || '3rem',
  margin: props.margin || '0'
}))`
  padding: ${props => (props.paddningNone ? '0' : '1.5rem')};
  margin: ${props => props.margin};
  background: ${props =>
    props.lightBlueBackground
      ? props => props.theme.lightBlue
      : props.lightBackground
        ? props => props.theme.lightGrey
        : 'white'};
  width: 100%;

  @media ${media.tablet} {
    padding: ${props => props.padding};
  }
`;
export { Section };
